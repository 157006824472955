@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 330 100% 64%;
    --primary-foreground: 330 100% 97%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 330 100% 64%;
    --radius: 0.5rem;
    --fancy: 70% 30% 40% 60% / 42% 30% 70% 58%;
    --fancy1: 43% 57% 67% 33% / 55% 44% 56% 45%;
    --text-body: rgb(54, 49, 61);
    --detail-medium-contrast: rgb(75, 75, 75);
    --text-high-contrast-rgb-value: 49, 49, 49;
    --detail-high-contrast: rgb(192, 192, 192);
    --text-color: black;
    --bg1: #ffffff;
    --border-consultation: #f5f8f7;
    --chart-1: 330 100% 64%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    .rdp-root {
      --rdp-accent-color: #ff47a3;
      --rdp-accent-background-color: hsl(240 4.8% 95.9%);

      --rdp-range_middle-background-color: var(--rdp-accent-background-color);
      --rdp-range_middle-foreground-color: white;
      --rdp-range_middle-color: inherit;

      --rdp-range_start-color: white;
      --rdp-range_start-background: var(--rdp-range_middle-background-color);
      --rdp-range_start-date-background-color: var(--rdp-accent-color);

      --rdp-range_end-background: var(--rdp-range_middle-background-color);
      --rdp-range_end-color: white;
      --rdp-range_end-date-background-color: var(--rdp-accent-color);
    }
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 330 100% 64%;
    --primary-foreground: 330 100% 97%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 330 100% 64%;
    --fancy: 70% 30% 40% 60% / 42% 30% 70% 58%;
    --fancy1: 43% 57% 67% 33% / 55% 44% 56% 45%;
    --text-body: rgb(222, 222, 222);
    --detail-medium-contrast: rgb(231, 231, 231);
    --text-high-contrast-rgb-value: 230, 230, 230;
    --detail-high-contrast: rgb(101, 101, 101);
    --text-color: white;
    --bg1: #121212;
    --border-consultation: #000000;
    --chart-1: 330 100% 64%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    .rdp-root {
      --rdp-accent-color: #ff47a3;
      --rdp-accent-background-color: hsl(12, 6%, 15%);

      --rdp-range_middle-background-color: var(--rdp-accent-background-color);
      --rdp-range_middle-foreground-color: white;
      --rdp-range_middle-color: inherit;

      --rdp-range_start-color: white;
      --rdp-range_start-background: var(--rdp-range_middle-background-color);
      --rdp-range_start-date-background-color: var(--rdp-accent-color);

      --rdp-range_end-background: var(--rdp-range_middle-background-color);
      --rdp-range_end-color: white;
      --rdp-range_end-date-background-color: var(--rdp-accent-color);
    }
  }

  img {
    width: auto;
    height: auto;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
}

.basic-padding {
  @apply px-4 lg:px-8 2xl:px-4;
}

/* .start-content-padding {
  @apply py-16 md:py-20;
} */

.start-content-padding {
  @apply py-32 sm:py-28 md:py-32;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply m-0 [-webkit-appearance:none];
}

/* Firefox */
input[type="number"] {
  @apply [-moz-appearance:textfield];
}

.prose img {
  width: 100%;
  border-radius: 4px;
  margin-top: 1rem !important;
}

.gradient {
  background-image: url("/images/background/gradient.png");
  background-size: 100% 100%;
  height: 37.0625rem;
}

.embla {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: auto;
  padding-bottom: 1rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-touch-action: pan-y pinch-zoom;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 var(--slide-size);
  flex: 0 0 var(--slide-size);
  min-width: 195px;
  padding-left: var(--slide-spacing);
  padding-bottom: 1rem;
  padding-top: 6px;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.embla__controls {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1.2rem 1fr;
  /* grid-template-columns: auto 1fr; */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  justify-content: flex-end;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 0.6rem 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: inset 0 0 0 0.17rem var(--detail-medium-contrast);
  box-shadow: inset 0 0 0 0.17rem var(--detail-medium-contrast);
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  -webkit-box-shadow: inset 0 0 0 0.17rem var(--detail-medium-contrast);
  box-shadow: inset 0 0 0 0.17rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  -webkit-box-shadow: inset 0 0 0 0.2rem #ff66c4;
  box-shadow: inset 0 0 0 0.2rem #ff66c4;
}
.embla_product {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.embla__viewport_product {
  overflow: hidden;
}
.embla__container_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-touch-action: pan-y pinch-zoom;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide_product {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 var(--slide-size);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number_product {
  -webkit-box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: var(--slide-height);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.embla-thumbs_product {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 6rem;
  margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport_product {
  overflow: hidden;
}
.embla-thumbs__container_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide_product {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 22%;
  flex: 0 0 22%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
}
@media (min-width: 576px) {
  .embla-thumbs__slide_product {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
  }
}
.embla-thumbs__slide__number_product {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  color: var(--detail-high-contrast);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: var(--thumbs-slide-height);
  width: 100%;
}

.isComingProduct::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 20px;
  background-image: -o-linear-gradient(130deg, #8f0c45 0%, #ff66c4 100%);
  background-image: linear-gradient(320deg, #8f0c45 0%, #ff66c4 100%);
  top: 30px;
  right: -50px;
  rotate: 230deg;
  z-index: 30;
}

.isComingProduct:after {
  font-size: 15px;
  content: "Już wkrótce!";
  position: absolute;
  rotate: 50deg;
  top: 43px;
  right: -6px;
  color: white;
  z-index: 30;
}

#mlb2-6053737 .ml-form-checkboxRow input[type="checkbox"]:checked ~ .label-description::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

#mlb2-6053737 .ml-form-checkboxRow input[type="checkbox"]:checked ~ .label-description::before {
  border-color: #ff47a3 !important;
  background-color: #ff47a3 !important;
}

.ml-error input,
.ml-error select,
.ml-error textarea,
.ml-error .label-description::before {
  border-color: red !important;
}

.ml-error .label-description,
.ml-error .label-description p,
.ml-error .label-description p a,
.ml-error label:first-child,
#mlb2-6053737 .ml-form-checkboxRow.ml-error .label-description p {
  color: red !important;
}

.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}

.crop :global(.ReactCrop__crop-selection) {
  border-image: none;
  border: 1px dashed #000;
}
.crop :global(.ReactCrop__drag-bar) {
  background: #fff;
}
.crop :global(.ReactCrop__drag-handle::after) {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
